import React from 'react'
import Navbarinner from "./Navbarinner"

const NavbarAbout = (props) => (
                        <nav role="navigation is-transparent" 
                        aria-label="main-navigation" 
                        className={props.sticky ? 'navbar is-fixed-top has-shadow' : 'navbar is-transparent'}>
                            <Navbarinner />
                        </nav>
    )

export default NavbarAbout