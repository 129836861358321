import React from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";
import NavbarAbout from "../components/Navbar/NavbarAbout";
import Footer from "../components/Footer/Footer"
import "./styles.scss";

export default class AboutLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    const { children } = this.props;
    return (
      <div id="wrapper" className="app has-navbar-fixed-top about contact">
        <Helmet>
          <meta name="description" content={config.siteDescription} />
          <html lang="en" />
        </Helmet>
        <NavbarAbout sticky={this.state.stickyNav} />
        <div className="app-body">
          {children}
        </div>
        <hr></hr>
        <Footer />
      </div>
    );
  }
}
