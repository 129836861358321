import React from 'react'
import { navigate } from "gatsby"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate("/"))
      .catch(error => alert(error))
  }

  render() {
    return (
      <form
        name="Santa Walt Contact Form"
        method="post"
        action="/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </div>
        <div className="field">
          <div className="control">
            <label className="label" htmlFor={'name'} for='input#name.input.is-medium'>
              <span style={{position: "absolute", opacity: "0"}}>Your name</span>
              <input
              className="input is-medium"
              type={'text'}
              name={'name'}
              onChange={this.handleChange}
              id={'name'}
              required={true}
              placeholder="Your name..." 
            />
            </label>
          </div>
        </div>
        <div className="field">
          <div className="control">
            <label className="label" htmlFor={'email'} for='input#email.input.is-medium'>
              <span style={{position: "absolute", opacity: "0"}}>Email address</span>
              <input
              className="input is-medium"
              type={'text'}
              name={'email'}
              onChange={this.handleChange}
              id={'email'}
              required={true}
              placeholder="Your email..." 
            />
            </label>
          </div>
        </div>
        <div className="field">
          <div className="control">
            <label className="label" htmlFor={'message'} for='input#message.textarea.is-medium'>
              <span style={{position: "absolute", opacity: "0"}}>Your message</span>
              <textarea
              className="textarea is-medium"
              type={'textarea'}
              name={'message'}
              onChange={this.handleChange}
              id={'message'}
              required={true}
              placeholder="Your message..." 
            />
            </label>
          </div>
        </div>
        <div className="field">
          <div class="control">
          <button style={{width: '100%'}} id="submit-btn" className="button is-primary is-medium is-link" type="submit">
            Submit
          </button>
          </div>
        </div>
      </form>
    )
  }
}