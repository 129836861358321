import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Form from "../Form/Form3"

import "./Contact.scss";

const Contact = () => {
    
    return (
        <section className="hero is-medium">
            <div className="hero-body content">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-three-fifths">
                            <div className="card">
                                <div className="card-content">
                                    <p className="subtitle is-size-5 has-text-centered" style={{ maxWidth: "450px", margin: "0 auto 20px" }}>Fill out the form below to <span className="alt-title">get in touch.</span></p>
                                    <div class="content has-text-black">
                                        <Form />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;
