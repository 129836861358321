import React, { Component } from "react";
import Helmet from "react-helmet";
import Layout from "../layout/about";
import config from "../../data/SiteConfig";
import Contact from "../components/Contact/Contact"

class ContactPage extends Component {
  render() {
    return (
      <Layout>
        <div className="home-container">
          <Helmet title={`Contact | ${config.siteTitle}`} />
          <div className="body-container contact">
            <Contact />
            <section className="hero is-small is-white has-background">
                <div className="hero-body">
                    <div className="container">
                    </div>
                </div>
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ContactPage;
